<template>
  <span>
    <v-btn
      v-if="$isMobile()"
      color="primary"
      @click="drawer = !drawer"
      fixed
      top
      left
      fab
      dark
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-navigation-drawer
      :color="color"
      v-model="drawer"
      :permanent="!$isMobile()"
      :temporary="$isMobile()"
      :mini-variant="$isMobile() ? false : mini"
      app
      :width="$isMobile() ? `100%` : `600px`"
      mini-variant-width="100"
      fixed
      flex
    >
      <v-list height="110px" class="menu-logo py-0">
        <v-list-item width="100%">
          <v-icon size="70">$logo</v-icon>
          <v-list-item-content>
            <v-list-item-title class="ml-3 wrap-text">
              <div>
                Prodia Sp z o.o.
                <br />Aleja Marszałka Józefa Piłsudskiego 105, 07-200 Wyszków
              </div>
              <v-menu offset-y content-class="elevation-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="mt-2 day-of-week-picker"
                    elevation="0"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dziś — {{ openedInfo }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    color="primary"
                    v-for="(day, index) in openedDays"
                    :key="index"
                  >
                    <!-- <v-chip dark :color="day.isClose ? 'red' : 'primary'" :outlined="day.isClose" label>
                        {{ day.name }}  {{ day.hours }}</v-chip
                      > -->

                    <v-list-item-title
                      :class="
                        index + 1 == dayOfWeek + 7
                          ? 'primary--text'
                          : index + 1 == dayOfWeek
                          ? 'primary--text'
                          : ''
                      "
                      >{{ day.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      :class="day.isClose ? 'red--text' : ''"
                      class="text-right"
                      >{{ day.hours }}</v-list-item-subtitle
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="menu-top py-0">
        <v-list-item
          @click.stop="$isMobile() ? (drawer = !drawer) : (mini = !mini)"
          class="drawerBtn"
          link
        >
          <v-list-item-icon>
            <v-icon size="50" v-if="mini" active>mdi-menu</v-icon>
            <v-icon size="50" v-else>mdi-chevron-left</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Schowaj menu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/" v-if="this.$route.name != 'Home'">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Strona główna</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="menu-center">
        <v-list-item
          link
          @click="toTop"
          :disabled="size.y / 2 > offsetY "
          :style="[
            size.y / 2 < offsetY 
              ? { opacity: 1, marginTop: `0px`,}
              : { opacity: 0, marginTop: `-56px`, },
              {transition: `all 0.2s cubic-bezier(0.4, 0, 0.6, 1) `}
          ]"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-up</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Wróć do góry</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :disabled="item.disabled"
          @click="goTo(item.link)"
        >
          <v-list-item-icon>
            <v-icon :class="{ 'costum-icon': item.isCostumIcon }">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="menu-button primary">
        <!-- <v-list color="grey darken-4" dark>
          <v-list-item class="pl-4" link to="oferta-flotowa">
            <v-list-item-icon class="my-0 mr-4">
              <v-icon size="70">$fleet</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Oferta flotowa</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-list dark>
          <v-list-item
            link
            href="https://goo.gl/maps/8SGmyFqkRqC9uujq5"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon style="transform: rotate(35deg)">mdi-navigation</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Nawiguj</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="snackbar = true">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zadzwoń</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      color="primary"
      :centered="$isMobile()"
      :right="!$isMobile()"
      v-model="snackbar"
      :timeout="15000"
    >
      <v-btn width="100%" :href="'tel:' + phoneNumber" text
        ><v-icon class="mr-2">mdi-phone-outgoing</v-icon>
        {{ phoneNumber }}</v-btn
      >

      <template v-slot:action="{ attrs }">
        <v-btn
          small
          elevation="0"
          fab
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
//import Stack from "@/assets/icons/rockrms.svg"
export default {
  name: "Menu",
  components: {},
  props: ["offsetY", "size"],
  data() {
    return {
      phoneNumber: "+48 513 707 887",
      snackbar: false,
      timeout: 15000,

      drawer: false,
      permanent: false,
      mini: true,
      dayOfWeek: null,
      openedInfo: null,
      openedDays: [
        {
          name: "Poniedziałek",
          hours: "7:00-18:00",
        },
        {
          name: "Wtorek",
          hours: "7:00-18:00",
        },
        {
          name: "Środa",
          hours: "7:00-18:00",
        },
        {
          name: "Czwartek",
          hours: "7:00-18:00",
        },
        {
          name: "Piątek",
          hours: "7:00-18:00",
        },
        {
          name: "Sobota",
          hours: "7:00-14:00",
        },
        {
          name: "Niedziela",
          hours: "Zamknięte",
          isClose: true,
        },
      ],
      items: [
        // {
        //   title: "Oferta",
        //   icon: "",
        //   disabled: true,
        // },
        {
          title: "O firmie",
          icon: "mdi-office-building",
          isCostumIcon: true,
          link: "zxc1",
        },
        {
          title: "Oferta",
          icon: "mdi-wrench",
          isCostumIcon: true,
          link: "oferta",
        },
        // {
        //   title: "Galeria",
        //   icon: "mdi-image-multiple",
        //   isCostumIcon: true,
        //   link: "zxc3",
        // },

        // {
        //   title: "O firmie",
        //   icon: "",
        //   disabled: true,
        // },
        {
          title: "Dane kontaktowe",
          icon: "mdi-card-account-phone",
          link: "dane-kontaktowe",
        },
      ],
      color: "#fff",
      miniVariant: true,
      expandOnHover: true,
      numScroll: 0,
      width: "450",
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      if (top > window.innerHeight / 2) {
        this.drawer = true;
      }
      this.numScroll =
        (100 / (document.body.scrollHeight - window.innerHeight)) * top;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    goTo(target) {
      this.$vuetify.goTo(`#${target}`, {
        offset: 0,
        duration: 700,
      });

      this.mini = true;
    },
  },
  computed: {},
  mounted() {},
  created() {
    const today = new Date();
    this.dayOfWeek = today.getDay();
    if (this.dayOfWeek == 0) {
      this.openedInfo = "Zamknięte";
    } else {
      this.openedInfo = `Otwarte ${this.openedDays[this.dayOfWeek - 1].hours}`;
    }
  },
};
</script>

<style lang="scss">
button.day-of-week-picker[aria-expanded="true"] i {
  transform: rotate(-180deg);
}

.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

.v-navigation-drawer--mini-variant {
  width: 100px !important;

  .v-list-item {
    justify-content: normal !important;
  }
}

.menu-logo {
  display: flex !important;

  path1 {
    background-image: linear-gradient(
      90deg,
      rgb(21, 221, 253) 0%,
      rgb(0, 108, 206) 100%
    ) !important;
    color: #fff !important;
    fill: #fff !important;
  }
}

.menu-top {
  width: 100%;

  .v-list-item {
    padding-left: 37px;
    &.drawerBtn {
      padding-left: 25px;
    }
  }
  s

  // background: #fff !important;

  i {
    color: #000;
  }
}

.menu-center {
  margin-top: auto;

  .v-list-item {
    padding-left: 37px;
  }
}

.menu-button {
  width: 100%;
  margin-top: auto;

  .v-list-item {
    padding-left: 37px;
  }
}

.menu-items {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 100%;
  height: 100%;
  margin: 0;

  .col {
    margin: 0;
    padding: 0;
  }
}

.progress {
  background: red;
  width: 5px;
  height: 100px;
  position: absolute;
  right: 0px;
  z-index: 99;
}
</style>
