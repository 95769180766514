<template>
  <span>
    <Front :offsetY="offsetY" />
    <About id="zxc1" />
    <Video :offsetY="offsetY" />
    <offer color="white" />

    <MainImage :size="size" :offsetY="offsetY" />
    <!-- <test id="zxc2" color="red" />
    <test id="zxc3" color="yellow" />
    <test id="zxc4" color="blue" /> -->
  </span>
</template>

<script>
import MainImage from "@/components/mainImage.vue";
import Front from "@/components/Front.vue";
import offer from "../components/offer.vue";
import About from "../components/About.vue";
import Video from "../components/Video.vue";

export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Stacja kontroli pojazdów i warsztat samochodowy - Prodia",
    // all titles will be injected into this template
    titleTemplate: null,
  },
  components: {
    MainImage,
    Front,
    About,
    Video,
    offer,
  },
  props: ["size", "offsetY"],
};
</script>
