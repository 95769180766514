<template>
  <v-container
    id="front"
    fluid
    :style="{
      //height: `${100 - offsetY / 4 > 50 ? 100 - offsetY / 4 : '50'}vh`,
    }"
  >
    <div
      :style="{
        position: 'absolute',
        transform: `translate(0,${offsetY / 3}px)`,
        opacity: `${1 - offsetY / 280}`,
      }"
      class="d-flex flex-column align-center"
    >
      <div>
        <v-icon size="220">$logo</v-icon>
      </div>
      <div class="industry">
        <div dark class="prof">Stacja kontroli pojazdów</div>
        <v-card rounded="0" elevation="0" class="division mx-8" color="primary">
        </v-card>
        <div class="prof">Warsztat samochodowy</div>
      </div>
      <!-- <div class="industry">
                  <div>Stacja kontroli pojazdów</div>
                  <v-card rounded="0" elevation="0" class="division mx-8" color="primary"></v-card>
                  <div>Warsztat samochodowy</div>
              </div> -->
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Front",

  props: ["offsetY"],
  data() {
    return {};
  },
  methods: {
    // onScroll(e) {
    //   // console.log(e);
    //   //this.offsetY = e.target.scrollingElement.scrollTop;
    // },
    
  },
};
</script>

<style lang="scss">
$breakpoint-tablet: 768px;

#front {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    z-index: 1;
  }

  &::after,
  &::before {
    //content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    background: rgb(0 0 0 / 80%);
  }

  &::before {
    background-image: url("~@/assets/bg.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .industry {
    font-weight: bold;
    font-size: 1.5em;
    text-transform: uppercase;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      font-size: 1.2em;
    }

    > .division {
      position: absolute;
      left: 50%;
      transform: translate(-50%) skewX(-20deg);
      padding: 0;
      width: 6px;
      margin: 0 !important;
      height: 65px;
      @media (max-width: $breakpoint-tablet) {
        position: relative;
        left: 0;
        margin: 30px 0 !important;
        transform: skewX(-20deg);
        width: 20vw;
        height: 6px;
      }
    }

    > .prof {
      width: 35vw;
      color: #fff;
      letter-spacing: 5px;
      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        width: 100%;
      }

      &:nth-of-type(1) {
        text-align: right;
        margin-right: 60px;
        @media (max-width: $breakpoint-tablet) {
          margin-right: 0;
          text-align: center;
        }
      }

      &:nth-of-type(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>
