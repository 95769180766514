<template>
  <v-container id="oferta" :style="{ background: color }" fluid class="front21">
    <v-layout row warp class="mb-8">
      <v-flex xs12 md8>
        <h3 :class="$isMobile() ? `mobile`: ``">
          <span
            v-for="(letter, index) in Array.from(title)"
            :key="index"
            data-aos="zoom-in"
            :data-aos-delay="100 * index"
            >{{ letter }}</span
          >
        </h3>
        <p class="mt-5" data-aos="fade-right" :data-aos-delay="500">
          <!-- Przykładowy opis.
          <br />Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Ducimus perferendis voluptatem consequatur nam aliquam aut, rem sit
          harum sapiente dignissimos ut ipsum esse, perspiciatis distinctio
          laudantium iusto, id possimus laborum? -->
        </p>
      </v-flex>
    </v-layout>
    <v-layout row warp v-for="(offer, index) in offers" :key="index" full-width>
      <v-flex xs12 md6 v-if="index % 2 == 0">
        <v-img
          :src="require('../assets/images/prodia-panorama-frimy.jpg')"
          aspect-ratio="1"
          class="grey lighten-2"
        ></v-img>
      </v-flex>
      <v-flex class="pa-8" xs12 md6>
        <h4>
          <span
            v-for="(letter, index) in Array.from(offer.name)"
            :key="index"
            data-aos="zoom-in"
            :data-aos-delay="50 * index"
            >{{ letter }}</span
          >
        </h4>
        <p class="mt-5" data-aos="fade-right" :data-aos-delay="500">
          {{ offer.text }}
        </p>
        <v-list class="pt-0">
          <v-list-item
            v-for="(point, index) in offer.points"
            :key="index"
            data-aos="fade-right"
            :data-aos-delay="index * 50"
            class="px-0"
          >
            <v-icon class="pr-3" color="primary">mdi-check</v-icon>
            <v-list-item-content>{{ point }}</v-list-item-content></v-list-item
          ></v-list
        >
        <v-layout row warp>
          <v-flex
            v-for="(service, index) in offer.services"
            :key="index"
            data-aos="fade-right"
            :data-aos-delay="index * 50"
            xs12
            sm6
            md4
            class="service"
            ><v-icon class="pr-3" color="primary" size="80"
              >${{ service.icon }}</v-icon
            >
            <span>{{ service.title }}</span></v-flex
          >
        </v-layout>
      </v-flex>
      <v-flex xs12 md6 v-if="index % 2 != 0">
        <v-img
          :src="require('../assets/images/prodia-warsztat.jpg')"
          aspect-ratio="1"
          class="grey lighten-2"
        ></v-img>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AOS from "aos";

export default {
  name: "Front2",
  components: {},
  props: ["color", "id"],
  data() {
    return {
      title: "OFERTA",
      offers: [
        {
          name: "Okręgowa Stacja Kontroli Pojazdów",
          text: "Stacja Kontroli Pojazdów w swojej ofercie posiada pełną diagnostykę pojazdów w tym:",
          points: [
            `okresowe badania techniczne samochodów osobowych, ciężarowych, specjalnych, autobusów, pojazdów marki "SAM"`,
            `okresowe badania techniczne przyczep, naczep, motocykli, motorowerów oraz ciągników rolniczych`,
            `okresowe badania techniczne wymagane przed pierwszą rejestracją w kraju`,
            `badania zgodności pojazdu zabytkowego z warunkami technicznymi`,
            `obsługa flot w zakresie okresowych badań technicznych`,
          ],
          services: [
            {
              icon: "checkingVehiclesBeforePurchasing",
              title: "Sprawdzenie pojazdów przed zakupem ",
              animation: "fade-right",
            },
            {
              icon: "technicalExaminationsAllVehicles",
              title: "Badania techniczne wszystkich pojazdów",
              animation: "zoom-in rotate",
            },
          ],
        },
        {
          name: "Warsztat",
          text: "Mechanika samochodowa oferuje Państwu kompleksową obsługę w zakresie wszelkiego rodzaju napraw pojazdów osobowych w tym:",
          points: [
            `serwis klimatyzacji`,
            `geometria kół`,
            `diagnostyka komputerowa`,
            `zmiany konstrukcyjne pojazdów`,
            `inne`,
          ],
          services: [
            {
              icon: "engine",
              title: "Układ napędowy",
              animation: "zoom-in",
            },
            {
              icon: "breakSystem",
              title: "Układ hamulcowy",
              animation: "rotate",
            },
            {
              icon: "airConditioning",
              title: "Klimatyzacja",
              animation: "fade-right",
            },
            {
              icon: "computerDiagnostics",
              title: "Diagnostyka komputerowa",
              animation: "zoom-in rotate",
            },
            {
              icon: "driveSystem",
              title: "Geometria kół",
              animation: "zoom-in rotate",
            },
            {
              icon: "electrician",
              title: "Elektryka",
              animation: "zoom-in rotate",
            },
            {
              icon: "oil",
              title: "Oleje",
              animation: "zoom-in rotate",
            },
            {
              icon: "runningRepairs",
              title: "Naprawy bieżące",
              animation: "zoom-in rotate",
            },
            {
              icon: "suspension",
              title: "Zawieszenie",
              animation: "zoom-in rotate",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss">
.service {
  display: flex;
  align-items: center;
}
.container {
  padding: 50px;
}
h3 {
  font-size: 5em;
}
h4 {
  font-size: 2.5em;
}
.front2 {
  height: 100vh;
  position: relative;
  display: flex;
}
.before-enter {
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.7s ease-out;
  transition-delay: 1s;
}
.enter {
  opacity: 1;
  transform: translateX(0px);
}
span.animIcon {
  .v-icon {
    transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

    .primary {
      fill: #000;
    }
  }
  &.aos-animate {
    .v-icon {
      .primary {
        transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
        transition-delay: 1.3s;
        fill: var(--v-primary-base);
      }
    }
  }
  &[data-aos="rotate"] {
    .v-icon {
      transform: rotate(180deg);
    }
    &.aos-animate {
      .v-icon {
        transform: rotate(0deg);
      }
    }
  }
}
</style>
