<template>
  <v-container class="pb-0">
    <v-footer padless id="dane-kontaktowe">
      <v-card flat tile style="width: 100%">
        <v-layout row warp>
          <v-flex xs12>
            <v-card flat>
              <h3 :class="$isMobile() ? `mobile`: ``">
                <span
                  v-for="(letter, index) in Array.from(title)"
                  :key="index"
                  data-aos="zoom-in"
                  :data-aos-delay="100 * index"
                  >{{ letter }}</span
                >
              </h3>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row warp>
          <v-flex md12>
            <v-layout row warp>
              <v-flex sm6 v-for="(item, i) in companyInfo" :key="i">
                <v-list>
                  <v-subheader class="justify-center">{{
                    item.name
                  }}</v-subheader>

                  <v-list-item
                    @click="item.notClicked ? `` : copyText(item.text)"
                    :href="item.link"
                    target="_blank"
                    link
                    :inactive="item.notClicked"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-tooltip
                      right
                      nudge-left="100"
                      :color="item.link ? `green` : `primary`"
                      :disabled="item.notClicked"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs" v-on="on">
                          <v-list-item-title :class="[typeof(item.text) == `number` ? `letter-spacing3` : ``, `wrap-text`]"
                            v-text="item.text"
                          ></v-list-item-title>
                          
                        </v-list-item-content>
                      </template>
                        <span v-if="item.link">Przejdź</span>
                        <span v-else>Skopiuj</span>
                    </v-tooltip>
                  </v-list-item>
                </v-list>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 class="mt-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2423.1365257307975!2d21.438630782364935!3d52.60330305095298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ee59e00170679%3A0xf0843e0f38cacc99!2sPRODIA!5e0!3m2!1spl!2spl!4v1634305122611!5m2!1spl!2spl" width="100%" height="600px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </v-flex>
        </v-layout>

        <v-divider class="mt-7"></v-divider>

        <div>
          <v-card-text class="primary--text text-center">
            © {{ new Date().getFullYear() }} – <strong>PROIDA</strong>.
            <br />Wszystkie prawa zastrzeżone.
          </v-card-text>

          <div class="white--text designed text-center">
            Created by <strong>CoreStudio</strong>
          </div>
        </div>
      </v-card>
      <v-snackbar color="success" v-model="snackbar" :timeout="2500">
        <v-icon class="mr-2">mdi-check</v-icon>
        Wartość <strong>{{ copyValue }}</strong> została zapisana w schowku!

        <template v-slot:action="{ attrs }">
          <v-btn
            small
            elevation="0"
            fab
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    title: "KONTAKT",
    copyValue: "",
    snackbar: false,
    companyInfo: [
      {
        name: "Pełna nazwa",
        text: "Prodia Michał Świderski",
        icon: "mdi-office-building",
      },
      {
        name: "Facebook",
        text: "www.facebook.com/OSKPPRODIA",
        icon: "mdi-facebook",
        link: "https://pl-pl.facebook.com/OSKPPRODIA/",
      },
      {
        name: "NIP",
        text: 7621976988,
        icon: "mdi-clipboard-text",
      },
      {
        name: "Regon",
        text: 364616934,
        icon: "mdi-clipboard-text",
      },

      {
        name: "Telefon",
        text: "+48 513 707 887",
        icon: "mdi-phone",
      },
      {
        name: "Mail",
        text: "biuro@prodia.pl",
        icon: "mdi-mail",
      },
      {
        name: "Adres",
        text: "Aleja Marszałka Józefa Piłsudskiego 105, 07-200 Wyszków",
        icon: "mdi-map-marker",
        link: "https://goo.gl/maps/8SGmyFqkRqC9uujq5",
      },
            {
        name: "Godziny otwarcia",
        text: "Pn - Pt — 07:00 - 18:00 | Sob — 07:00 - 14:00",
        icon: "mdi-clock",
        notClicked: true,
      },
    ],
  }),
  methods: {
    copyText(textToCopy) {
      const tmpTextField = document.createElement("textarea");
      tmpTextField.textContent = textToCopy;
      tmpTextField.setAttribute("style", "position:absolute; right:200%;");
      document.body.appendChild(tmpTextField);
      tmpTextField.select();
      tmpTextField.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      tmpTextField.remove();
      this.copyValue = textToCopy;
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss">
.letter-spacing3{
  letter-spacing: 3px;
}
.column_wrapper {
  max-height: 200px;
  display: flex;
  flex-flow: column wrap;
}
.designed {
  background: rgb(0, 0, 0);
  font-size: 0.8rem;
  padding: 5px 15px;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%);

  &::after,
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
  }

  &::before {
    left: -39px;
    width: 0;
    height: 0;
    border-bottom: 29px solid #000;
    border-left: 40px solid transparent;
    margin: 29px auto 0;
  }

  &::after {
    right: -39px;
    width: 0;
    height: 0;
    border-bottom: 29px solid #000;
    border-right: 40px solid transparent;
    margin: 29px auto 0;
  }
}
</style>
