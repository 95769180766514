<template>
  <div class="slider" @mousedown="startDrag" @mousemove="doDrag">
    <v-btn
      class="mx-2"
      fab
      id="left-btn"
      :class="{ hide: this.position <= this.limit.left }"
      @click="moveLeft()"
      elevation="0"
    >
      <v-icon size="30" color="primary"> mdi-chevron-left </v-icon>
    </v-btn>

    <ul
      class="tape"
      :class="{ drag: dragging }"
      :style="{ right: `${position}px` }"
    >
      <slot />
    </ul>
    <v-btn
      class="mx-2"
      fab
      id="right-btn"
      :class="{ hide: this.position >= 0 }"
      @click="moveRight()"
      elevation="0"
    >
      <v-icon size="30" color="primary"> mdi-chevron-right </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "slider",
  props: {
    width: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      limit: {
        right: 0,
        left: 0,
      },
      tape: {
        totalWidth: 0,
        visibleWidth: 0,
      },
      el: {
        width: this.width,
      },
      position: 0,
      dragging: false,
      x: null,
      start: 0,
      dataToBeWatched: "",
    };
  },
  watch: {
    content() {
      this.$nextTick(() => {
        console.log(this.$el);
        this.tape.visibleWidth = this.$el.offsetWidth;
      });
    },
  },
  methods: {
    moveLeft() {
      let totalElements = this.$el.querySelectorAll("ul li");
      let visibleElements = Math.floor(this.$el.offsetWidth / this.el.width);
      let firstElement = Math.ceil(
        totalElements.length -
          1 -
          visibleElements +
          this.position / this.el.width
      );

   
      this.dataToBeWatched = "s";
      if (firstElement > 0) {
        this.position = parseFloat((this.position - this.el.width).toFixed(3));
      } else {
        this.position = this.limit.left;
      }
    },
    moveRight() {
      let newPosition = 0;
      if (this.position < this.limit.right) {
        newPosition = parseFloat((this.position + this.el.width).toFixed(3));
      }
      if (newPosition > this.limit.right) {
        newPosition = this.limit.right;
      }
      this.position = newPosition;
    },

    generateSliderElements() {
      this.dataToBeWatched = this.$el.offsetWidth;
      this.tape.visibleWidth = this.$el.offsetWidth;
      let width = this.el.width;
      let sum = width * this.length;

      this.tape.totalWidth = sum;
      this.limit.left = -(
        this.tape.totalWidth -
        (this.tape.visibleWidth - 400)
      );
    },
    startDrag(event) {
      this.dragging = true;
      this.x = 0;
      this.start = event.clientX;
    },
    stopDrag() {
      this.dragging = false;
      this.x = null;
      this.start = 0;
      if (this.position <= this.limit.left) {
        this.position = this.limit.left;
      }
      if (this.position > this.limit.right) {
        this.position = this.limit.right;
      }
    },
    doDrag() {
      if (this.dragging) {
        this.x = this.start - event.clientX;
        if (this.x > this.start) this.position -= this.x;
        else this.position += this.x;

        this.start = event.clientX;
      }
    },
  },
  mounted() {
    this.generateSliderElements();
    window.addEventListener("mouseup", this.stopDrag);
    this.position = this.limit.left;
  },
  created() {},
};
</script>
<style lang="scss">
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
  display: flex;
  justify-content: flex-end;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > button {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0%, -50%);
    height: 100%;
    width: 50px;
    //background: rgb(255, 255, 255);
    // background: linear-gradient(
    //   90deg,
    //   rgba(255, 255, 255, 1) 37%,
    //   rgba(255, 255, 255, 0) 100%
    // );
    -webkit-animation: fadeIn 0.5s both;
    animation: fadeIn 0.5s both;

    i {
      font-size: 2.5rem;
    }

    &#left-btn {
      left: 8px;
    }

    &#right-btn {
      right: 8px;
      // background: linear-gradient(
      //   270deg,
      //   rgba(255, 255, 255, 1) 37%,
      //   rgba(255, 255, 255, 0) 100%
      // );
    }

    &:focus {
      outline: none;
    }

    &.hide {
      -webkit-animation: fadeOut 0.3s both normal;
      animation: fadeOut 0.3s both normal;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }

      1% {
        opacity: 0;
        visibility: visible;
      }

      to {
        opacity: 1;
        z-index: 1;
      }
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }

      99% {
        opacity: 0;
        z-index: 1;
      }

      100% {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  ul {
    position: absolute;
    right: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    height: 100%;
    transition: right 1s cubic-bezier(0, 0.23, 0, 0.98);

    &.drag {
      transition: none;
    }

    li {
      display: block;
      align-items: center;
      justify-content: center;
      height: 100%;

      > div {
        //border: 2px #2b81d6 solid;
        margin: 5px;
        padding: 10px;
      }
    }
  }
}
</style>
