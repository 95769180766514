<template>
  <div
    id="mainImage"
    :style="{
      top: `${offsetY * 4 < size.y * 2 ? offsetY * 4 : size.y * 2}px`,
      display: `${offsetY * 2 < size.y ? 'block' : 'none'}`,
    }"
  >
    <div
      :style="{
        opacity: `${
          1 - offsetY / (size.x / 5) >= 0 ? 1 - offsetY / (size.x / 5) : 0
        }`,
      }"
      class="overlay"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["offsetY", "size"],
};
</script>

<style lang="scss">
#mainImage {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: url("~@/assets/bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  -webkit-transition: all 0.4 ease-in-out;
  -moz-transition: all 0.4 ease-in-out;
  -o-transition: all 0.4 ease-in-out;
  transition: all 0.4 ease-in-out;

  .overlay {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 80%);
  }
}
</style>