import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import scrollAnim from './directives/scrollanim';

Vue.directive('scrollAnim', scrollAnim)

Vue.config.productionTip = false;

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);


// import VueAnimateOnScroll from 'vue-animate-onscroll'
// Vue.use(VueAnimateOnScroll)

// import VueAos from 'vue-aos'
// Vue.use(VueAos)

import 'aos/dist/aos.css'


import 'vue-video-section/dist/vue-video-section.css'
import VueVideoSection from 'vue-video-section'
Vue.component('vue-video-section', VueVideoSection)

import Meta from 'vue-meta'
Vue.use(Meta);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
