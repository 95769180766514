<template>
  <div id="app" v-scroll="onScroll">
    <v-app>
      <Menu :offsetY="offsetY" :size="size"/>
      <v-main>
        <router-view :offsetY="offsetY" :size="size" />
        <Footer />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";

export default {
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      offsetY: 0,
      size: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    onScroll(e) {
      this.offsetY = e.target.scrollingElement.scrollTop;
    },
  },
  created() {
    this.size.x = window.innerWidth;
    this.size.y = window.innerHeight;
  },
};
</script>

<style lang="scss">
* {
  font-family: "Montserrat", Helvetica, Arial;
  box-sizing: border-box;
}
.theme--dark path {
  fill: #fff;
}
.primary--text .primary {
  fill: var(--v-primary-base);
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
h3 {
  &.mobile {
    font-size: 3em;
  }
}
.wrap-text {
  white-space: normal !important;
}
</style>
