<template>
  <v-container
    :id="id"
    :style="{ background: color }"
    fluid
    class="front21 py-5"
  >
    <v-layout row warp>
      <v-flex xs12 md6>
        <h3 :class="$isMobile() ? `mobile` : ``">
          <span
            v-for="(letter, index) in Array.from(title)"
            :key="index"
            data-aos="zoom-in"
            :data-aos-delay="100 * index"
            >{{ letter }}</span
          >
        </h3>
        <p class="mt-5 pr-2" data-aos="fade-right" :data-aos-delay="500">
          Stacja Kontroli Pojazdów oraz stanowiska warsztatowe wyposażone są w
          najwyższej jakości sprzęt oraz specjalistyczne oprogramowanie,
          pozwalające na indywidualne i rzetelne zdiagnozowanie oraz naprawę
          każdego typu pojazdu. Wiedza i doświadczenie naszych pracowników
          pozwala na sprawną obsługę nawet najbardziej wymagających Klientów.
        </p>
      </v-flex>
      <v-flex xs12 md6>
        <Slider
          id="windHistory"
          :width="400"
          :length="10"
          data-aos="fade-in"
          :data-aos-delay="700"
        >
          <li
            v-for="(image, i) in images"
            :key="i"
            :style="{ width: `400px` }"
            align="center"
            justify="center"
          >
            <div>
              <v-img
                v-if="i <= 2"
                :src="require(`../assets/images/gallery/${image.name}.jpg`)"
                :alt="image.alt"
                aspect-ratio="1"
                class="grey lighten-2"
                data-aos="zoom-in"
                :data-aos-delay="800 + 300 * i"
              ></v-img>
              <v-img
                v-else
                :src="require(`../assets/images/gallery/${image.name}.jpg`)"
                aspect-ratio="1"
                class="grey lighten-2"
                :alt="image.alt"
              ></v-img>
            </div>
          </li>
        </Slider>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AOS from "aos";
import Slider from "@/components/Slider";
export default {
  name: "Front2",
  components: { Slider },
  props: ["color", "id"],
  data() {
    return {
      title: "O FIRMIE",
      images: [
        {
          name: "front",
          alt: "Prodia Wyszków",
        },
        {
          name: "biuro",
          alt: "Biuro Prodia Wyszków",
        },
        {
          name: "skp3",
          alt: "Okresowe badania techniczne - Prodia",
        },
        {
          name: "biuro2",
          alt: "Biuro Prodia Wyszków",
        },
        {
          name: "warsztat4",
          alt: "Naprawa uszkodzonych pojazdów - Prodia Wyszków",
        },
        {
          name: "skp1",
          alt: "Stacja Kontroli Pojazdów Wyszów - Prodia",
        },
        {
          name: "warsztat1",
          alt: "Naprawa samochodów osobowych",
        },
        {
          name: "skp3",
          alt: "Okresowe badania techniczne - Prodia",
        },
        {
          name: "warsztat2",
          alt: "Wymiana rozrządu - Prodia Wyszków",
        },
        {
          name: "skp2",
          alt: "Badania techniczne pojadzów - Prodia",
        },
        {
          name: "warsztat3",
          alt: "Mechanika samochdowa - Prodia Wyszków",
        },
      ],
      services: [
        {
          icon: "engine",
          title: "Układ napędowy",
          animation: "zoom-in",
        },
        {
          icon: "breakSystem",
          title: "Układ hamulcowy",
          animation: "rotate",
        },
        {
          icon: "airConditioning",
          title: "Klimatyzacja",
          animation: "fade-right",
        },
        {
          icon: "checkingVehiclesBeforePurchasing",
          title: "Sprawdzenie pojazdów przed zakupem ",
          animation: "fade-right",
        },
        {
          icon: "computerDiagnostics",
          title: "Diagnostyka komputerowa",
          animation: "zoom-in rotate",
        },
        {
          icon: "driveSystem",
          title: "Geometria kół",
          animation: "zoom-in rotate",
        },
        {
          icon: "electrician",
          title: "Elektryka",
          animation: "zoom-in rotate",
        },
        {
          icon: "oil",
          title: "Oleje",
          animation: "zoom-in rotate",
        },
        {
          icon: "runningRepairs",
          title: "Naprawy bieżące",
          animation: "zoom-in rotate",
        },
        {
          icon: "suspension",
          title: "Zawieszenie",
          animation: "zoom-in rotate",
        },
        {
          icon: "technicalExaminationsAllVehicles",
          title: "Badania techniczne wszystkich pojazdów",
          animation: "zoom-in rotate",
        },
      ],
    };
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss">
.container {
  padding: 50px;
}
h3 {
  font-size: 5em;

  &.mobile {
    font-size: 3em;
  }
}
.front2 {
  height: 100vh;
  position: relative;
  display: flex;
}
.before-enter {
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.7s ease-out;
  transition-delay: 1s;
}
.enter {
  opacity: 1;
  transform: translateX(0px);
}
span.animIcon {
  .v-icon {
    transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;

    .primary {
      fill: #000;
    }
  }
  &.aos-animate {
    .v-icon {
      .primary {
        transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
        transition-delay: 1.3s;
        fill: var(--v-primary-base);
      }
    }
  }
  &[data-aos="rotate"] {
    .v-icon {
      transform: rotate(180deg);
    }
    &.aos-animate {
      .v-icon {
        transform: rotate(0deg);
      }
    }
  }
}
</style>
