<template>
  <vue-video-section
    :elementId="'vid'"
    :mp4Source="require('../assets/Videos/company.mp4')"
    :mobileBreakpoint="992"
    :desktopHeight="550"
    :mobileHeight="450"
    :playsinline="false"
    :loop="false"
    :autoplay="true"
    :autobuffer="true"
    :muted="true"
  >
  </vue-video-section>
</template>
<script>
export default {
  props: ["offsetY"],
  data() {
    return {
      time: 0,
      val: 0,
    };
  },
  methods: {
    zxc() {},
  },
  watch: {
    offsetY: function () {
      if (this.offsetY == 0) {
        let vid = document.querySelector("#vid");
        vid.currentTime = 0;
      }

      // vid.currentTime = newValue / 80;
      // let val = 0.1;
      // this.time += 40;
      // if (newValue < 200 || this.val > 20) return;
      // let int = setInterval(() => {
      //   if (this.time <= 0) {
      //     clearInterval(int);
      //   } else {
      //     this.time -= 20;
      //   }
      //   if (newValue > oldValue) {
      //     this.val+=val
      //   } else {
      //     this.val-=val
      //   }

      //   console.log(this.val);
      //   vid.currentTime = this.val;
      // }, this.time);
    },
  },
};
</script>