import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);




export default new Vuetify({
  theme: {
    options: { customProperties: true },
  },
  icons: {
    values: {
      logo: {
        component: () => import("@/components/svg_icons/logo.vue")
      },
      fleet: {
        component: () => import("@/components/svg_icons/fleet.vue")
      },
      breakSystem: {
        component: () => import("@/components/svg_icons/breakSystem.vue")
      },
      engine: {
        component: () => import("@/components/svg_icons/engine.vue")
      },      
      airConditioning: {
        component: () => import("@/components/svg_icons/airConditioning.vue")
      },
      checkingVehiclesBeforePurchasing: {
        component: () => import("@/components/svg_icons/checkingVehiclesBeforePurchasing.vue")
      },
      computerDiagnostics: {
        component: () => import("@/components/svg_icons/computerDiagnostics.vue")
      },
      driveSystem: {
        component: () => import("@/components/svg_icons/driveSystem.vue")
      },
      electrician: {
        component: () => import("@/components/svg_icons/electrician.vue")
      },
      oil: {
        component: () => import("@/components/svg_icons/oil.vue")
      },
      runningRepairs: {
        component: () => import("@/components/svg_icons/runningRepairs.vue")
      },
      suspension: {
        component: () => import("@/components/svg_icons/suspension.vue")
      },
      technicalExaminationsAllVehicles: {
        component: () => import("@/components/svg_icons/technicalExaminationsAllVehicles.vue")
      },
    },
  },
});
